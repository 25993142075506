.profile {
  //   min-height: calc(100vh - 270px);
  //new fix
  // min-height: 110vh;
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px !important;
  }
  padding: 30px 130px 60px 130px;
  @media only screen and (max-width: 919px) {
    padding: 30px 30px 60px 30px;

    width: 100%;
  }
  @media only screen and (max-width: 700px) {
    padding: 20px 20px 50px 20px;
  }
  @media only screen and (max-width: 550px) {
    display: block;
  }
  .ant-select-selection-search {
    padding-top: 6px;
    padding-left: 27px;
    height: 30px;
  }

  .profile_content {
    max-width: 696px;

    width: 100%;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      color: #000000;
      width: 100%;
      margin-bottom: 20px !important;
      @media only screen and (max-width: 550px) {
        margin-bottom: 0 !important;
      }
    }
    .profile_details {
      width: 100%;
      display: grid;
      justify-content: center;
      //   flex-direction: column;
      grid-gap: 16px;
      grid-template-columns: repeat(12, minmax(auto, calc(100% / 12)));
      margin-bottom: 16px;
      .input_profile {
        grid-column-end: span 6;
        @media only screen and (max-width: 550px) {
          grid-column-end: span 12;
          width: 100%;
        }
      }
    }
    .btn_edit {
      width: 49%;
      margin-bottom: 56px;
      @media only screen and (max-width: 550px) {
        width: 100%;
      }

      button {
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .change_password {
    width: 100%;

    .profile_content {
      max-width: 696px;
      width: 100%;

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        color: #000000;
        width: 100%;
        @media only screen and (max-width: 550px) {
          margin-bottom: 10px !important;
        }
      }
      .profile_details {
        width: 100%;
        display: grid;
        justify-content: center;
        //   flex-direction: column;
        gap: 16px;
        grid-template-columns: repeat(12, minmax(auto, calc(100% / 12)));
        margin-bottom: 16px;
        .input_profile {
          grid-column-end: span 6;
          @media only screen and (max-width: 550px) {
            grid-column-end: span 12;
            width: 100%;
          }
        }
        .empty {
          @media only screen and (max-width: 550px) {
            display: none;
          }
        }
      }
      .btn_edit {
        width: 49%;
        @media only screen and (max-width: 550px) {
          width: 100%;
        }
        button {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.ant-input-affix-wrapper {
  .ant-input:not(textarea) {
    padding: 5px !important;
  }
}
