.carousel {
  display: flex;
  flex-direction: column;

  .keen-slider__slide.number-slide1 {
    max-height: 446px;
    min-height: 446px;

    display: flex;
    // align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 1051px) {
    // .keen-slider__slide {
    // max-width: unset !important;
    // min-width: 400px !important;
    // }
  }

  [class^="number-slide"],
  [class*=" number-slide"] > img {
    height: 100%;
    width: 100%;
    min-height: 340px;
    max-height: 387px;
    border-radius: 15px;
    object-fit: fill;
    max-width: 600px;
  }
  @media only screen and (max-width: 700px) {
    [class^="number-slide"],
    [class*=" number-slide"] > img {
      height: 100%;
      width: 100%;
      // min-height: 100%;
      // max-height: 100px;
      // max-width: 100%;
      //to be removed
      max-height: 227px;
    }
  }
  .navigation-wrapper {
    position: relative;
    width: 100%;
    // min-height: 100%;
    //to be removed
    @media only screen and (max-width: 700px) {
      height: 350px !important;
    }
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #000;
  }

  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }

  .share {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    width: 52px;
    height: 52px;
    padding: 0px 6.594px;
    justify-content: center;
    align-items: center;
    gap: 16.486px;
    border-radius: 221.663px;
    background: var(--green, #04c23a);
    z-index: 4;
    cursor: pointer;
    @media only screen and (max-width: 700px) {
      width: 26px;
      height: 26px;
      left: 10px;
      top: 10px;
      img {
        width: 17px;
      }
    }
  }
  .Available {
    position: absolute;
    z-index: 1;
    left: 20px;
    bottom: 125px;
    // top: 20px;
    height: fit-content;
    border-radius: 285.337px;
    background: var(--green, #04c23a);
    display: flex;
    padding: 0px 9.511px;
    align-items: flex-start;
    gap: 23.778px;
    color: var(--white, #fff);
    text-align: center;
    font-size: 17.778px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
    @media only screen and (max-width: 700px) {
      left: 10px;
      bottom: 165px;
      color: var(--white, #fff);
      text-align: center;
      font-size: 14.858px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .soldout {
    position: absolute;
    z-index: 2;
    left: 20px;
    bottom: 115px;
    height: fit-content;
    display: flex;
    padding: 0px 9.511px;
    align-items: flex-start;
    gap: 23.778px;
    color: var(--white, #fff);
    text-align: center;
    font-size: 17.778px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 217.13px;
    background: var(--red, #fd2a2a);
    width: fit-content;

    @media only screen and (max-width: 700px) {
      left: 10px;
      bottom: 165px;
      color: var(--white, #fff);
      text-align: center;
      font-size: 14.858px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
