.not__found__container {
  background-image: url("../../assets/icons/404.svg");
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 35px 20px;
  @media only screen and (max-width: 860px) {
    justify-content: center;
    margin-top: 80px;
    padding-top: 0;
  }
  & > p {
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #aeaeae;
    margin-top: 20px;
    @media only screen and (max-width: 730px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media only screen and (max-width: 560px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fd572a;
    text-decoration: none;
    border: 1px solid #fd572a;
    border-radius: 12px;
    max-width: 321px;
    width: 100%;
    padding: 10px 10px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fd572a;
      color: white;
      transition: all 0.3s;
    }
    @media only screen and (max-width: 560px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 13px;
      margin-top: 10px;
    }
  }
}
