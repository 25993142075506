.empty_cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  img {
    width: 100%;
    max-width: 600px;
  }
  .s_text {
    max-width: 320px;
    width: 100%;
    text-align: center;
    margin-top: -80px;
    margin-bottom: 100px !important;
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      color: #000000;
      margin-bottom: 12px !important;
    }
  }
}
