.footer {
  background: var(--green, #04c23a);
  width: 100%;
  padding: 27px 150px 31px 150px;
  // display: flex;
  // align-items: center;
  // gap: 30px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  .left {
    width: 100%;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px; /* white */
      color: #ffffff;
      margin-bottom: 14px !important;
    }
    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #ffffff;
      margin-top: 10px;
      max-width: 600px;
    }
  }
  .right {
    width: 100%;
    margin-top: 30px;
    .icons {
      // justify-content: flex-end;
      // gap: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;

      color: #ffffff;
      margin-bottom: 5px;

      display: grid;
      grid-template-columns: auto;
      justify-items: end;
      grid-gap: 6px;
      display: flex;
      justify-content: flex-end;
    }
    .items {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;

      color: #ffffff;
      // margin-bottom: 6px;
      .signup {
        cursor: pointer;
      }
      .ar {
        cursor: pointer;
      }
    }
    .last_privacy {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;

      color: #ffffff;

      .text1 {
        margin-right: 5px;
      }
    }
  }
  @media only screen and (max-width: 1389px) {
    padding: 20px 50px;
  }
  @media only screen and (max-width: 973px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    .right {
      justify-content: flex-start;
      .icons {
        justify-content: flex-start;
      }
      .items {
        justify-content: flex-start;
      }
      .last_privacy {
        justify-content: flex-start;
      }
    }
  }
}

.nav_link {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}
