@use "../ProductCard/ProductCard";
.list_items {
  // display: flex;
  // gap: 38px;
  flex-wrap: wrap;
  max-height: 740px;
  overflow-y: auto;

  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(220px, max-content));
  align-items: center;
  justify-content: center;
  grid-gap: 38px;
  @media screen and (max-width: 900px) {
    gap: 18px;
    grid-gap: 18px;
  }
  @media screen and (max-width: 620px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }

  .product_card {
    @media screen and (max-width: 600px) {
      min-width: 100%;
      border: none;
      max-width: 100%;
      padding: 0;
    }
  }
}
.small_list {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 100% !important;
  .product_card {
    width: 292px;
    @media only screen and (max-width: 1124px) {
      max-width: 238px;
      width: 100%;
    }
    @media only screen and (max-width: 533px) {
      max-width: unset;
    }
    @media screen and (max-width: 600px) {
      min-width: 100%;
      border: none;
      max-width: 100%;
      padding: 0;
      width: 100%;
    }
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.list_items::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list_items {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.spinner_product {
  min-height: 80px;
  width: 100%;
}
