.img_logo {
  height: 57px;
  // object-fit: cover;
  width: 212px !important;
  // @media only screen and (max-width: 1089px) {
  //   width: 150px;
  //   height: 40px;
  // }
}
// .logo_en {
//   margin-left: -35px;
// }
// .logo_ar {
//   margin-right: -35px;
// }
