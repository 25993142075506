@use "../Collapse/Collapse";
@use "../FilterItem/FilterItem";
.filter {
  width: 100%;
  .divider {
    background: var(--green, #04c23a);
    width: 100%;
    height: 1px;
    margin: 24px 0;
  }
  .slider_filter {
    .filter_text {
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      color: #000000;
    }
    .ant-slider {
      width: 80%;
      .ant-slider-rail {
        // background: var(--green, #04c23a);
        background: var(--gray-4, #bdbdbd);
        border-radius: 12px;
        height: 6px;
      }
      .ant-slider-track {
        background: var(--green, #04c23a);
        border-radius: 12px;
        height: 6px;
      }
      .ant-slider-handle {
        margin-top: -7px;
      }
      .ant-slider-handle.ant-slider-handle-1 {
        &::after {
          background: var(--green, #04c23a);
          width: 24px;
          height: 24px;
          // margin-top: -4px;
          box-shadow: none;
          border-radius: 12px;
        }
      }
      .ant-slider-handle.ant-slider-handle-2 {
        &::after {
          background: var(--green, #04c23a);
          width: 24px;
          height: 24px;
          // margin-top: -5px;
          border: none;
          box-shadow: none;
          border-radius: 12px;
        }
      }
    }
    .values_slider {
      display: flex;
      justify-content: space-between;
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .action_filter {
    // display: flex;
    // text-align: center;
    // justify-content: center;
    // gap: 10px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    .btn_1 {
      width: 70%;
      padding: 10px 32px;
      // height: 42px;
      background: var(--green, #04c23a);
      border-radius: 8px;
      border: none;
      display: flex;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;

      color: #ffffff;
      cursor: pointer;
      width: 100%;
    }
    .btn_2 {
      width: 30%;
      padding: 10px 32px;
      // height: 42px;
      background: #bdbdbd;
      border-radius: 8px;
      border: none;
      display: flex;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;

      color: #ffffff;
      cursor: pointer;
      width: 100%;
    }
  }
}
