.header {
  display: flex;
  padding: 13px 20px;
  width: 100%;
  // height: 100px;
  border: 0.5px solid #e0e0e0;
  justify-content: space-between;
  align-items: center;

  & > .header__group {
    margin: 5px 0;
    display: flex;
    gap: 18px;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    // text-align: right;
    background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    cursor: pointer;
    user-select: none;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }
    .search_header {
      width: 320px;
    }
  }
  .header__right {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // gap: 24px;
    // @media only screen and (max-width: 1089px) {
    //   flex-grow: 1;
    //   justify-content: space-between;
    // }
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-items: space-between;
    align-items: center;
    grid-gap: 24px;

    @media only screen and (max-width: 1089px) {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: 0px;
    }
    .item_header {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #868889;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      cursor: pointer;

      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-items: center;
    }
    .sign_up_header {
      background: none;
      border: none;
      // gap: 4px;
      grid-gap: 5px;
    }
    .login_header {
      cursor: pointer;
      border: none;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      // display: flex;
      // align-items: center;
      color: #ffffff;
      // gap: 10px;
      height: 46px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      border-radius: 6px;
      padding: 0 24px;

      //new css
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-items: center;
      grid-gap: 10px;
    }
    .lang_header {
      background: none;
      border: none;
      // gap: 4px;
      grid-gap: 4px;
    }
  }
  .navigation_link {
    color: black !important;
  }

  .exit_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
