/**Typeo CSS End**/
.video-custom {
  //   margin: 40px auto;
  //   max-width: 800px;
  //   position: relative;
  //   max-height: 400px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d6d6d6;
  min-height: 340px;
  max-height: 387px;
  @media only screen and (max-width: 700px) {
    height: 350px !important;
  }
}

.video-custom .poster-img {
  display: block;
}

.video-custom video {
  //   position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100%;
  //   opacity: 0;
  border-radius: 12px;
  //   margin-bottom: 50px;
}

.video-custom .pause-btn {
  display: none;
}

.playvideo .video-custom img {
  opacity: 0;
}

.playvideo .video-custom video {
  opacity: 1;
}

.video-custom .vplay {
  display: none;
}

.video-custom:hover .vplay + .pause-btn {
  display: block;
}

/***Volume Control***/
// html:not(.playvideo) .sound-ctrl {
//   display: none;
// }

.sound-ctrl {
  position: absolute;
  z-index: 2;
  right: 14px;
  bottom: 5px;
  cursor: pointer;
}

.sound-ctrl .on-button {
  display: none;
}

.sound-ctrl img {
  opacity: 0;
}

.sound-ctrl .off-button,
.sound-ctrl .on-button {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 0px;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  cursor: pointer;
}

.soundctrlshow .on-button {
  display: block;
}

.soundctrlshow .off-button {
  display: none;
}

.playvideo .video-custom .sound-ctrl img {
  opacity: 1;
}

.soundctrlshow .on-button:before {
  content: "";
  position: absolute;
  left: 12px;
  top: -5px;
  z-index: 10;
  width: 3px;
  height: 32px;
  background-color: #fff;
  transform: rotate(45deg);
}
