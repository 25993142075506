.cart_item {
  border: 1px solid #f2f2f2;
  padding: 10px;
  display: flex;
  gap: 8px;
  margin-right: 4px;

  @media only screen and (max-width: 600px) {
    border: none;
  }

  .image {
    position: relative;
    border-radius: 8px;
    width: 100px;
    height: 100px;
    .img_item {
      border-radius: 8px;
      width: 100px;
      height: 100px;
      object-fit: fill;
    }
    .delete_cart {
      position: absolute;
      width: 19px;
      height: 20px;
      left: 4px;
      top: 4px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      padding: 2px;
    }
    .delete_cart_ar {
      position: absolute;
      width: 19px;
      height: 20px;
      right: 4px;
      top: 4px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      padding: 2px;
    }
  }
  .left_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .details {
      //   margin-top: 10px;
      .title_cart {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #000000;
      }
      .size_item {
        font-weight: 700;
        font-size: 10px;
        line-height: 19px;
        color: #000000;
      }
      .place_item {
        font-weight: 700;
        font-size: 8px;
        line-height: 15px;
        color: #000000;
      }
      .price {
        font-weight: 700;
        font-size: 10px;
        line-height: 19px;
        color: #000000;
        .kg {
          font-weight: 400;
        }
      }
    }
    .top_right {
      .add_rem {
        display: flex;
        justify-content: center;
        gap: 8px;
        align-items: center;

        .plus {
          background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
          border-radius: 8px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
          user-select: none;
          img {
            width: 12px;
            height: 12px;
          }
        }
        .count {
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          color: #000000;
        }
      }
      .price {
        font-weight: 700;
        font-size: 10px;
        line-height: 19px;
        text-align: center;
        color: #000000;
      }

      .price_t {
        background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
