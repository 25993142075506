@use "./components/CartItem/CartItem";
@use "./components/EmptyCart/EmptyCart";
// @use "./components/ShippingItem/ShippingItem";
@use "./components/InvoiceItem/InvoiceItem";
@use "./components/ShoppingOption/ShoppingOption";

.section_cart {
  padding: 60px 150px;
  //new fix
  // min-height: 135vh;
  @media only screen and (max-width: 1300px) {
    padding: 60px 60px;
  }
  @media only screen and (max-width: 1030px) {
    padding: 60px 30px;
  }
  @media only screen and (max-width: 900px) {
    padding: 30px 20px;
  }
  .cart_content {
    display: flex;
    gap: 8px;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
  .left {
    width: 100%;
    min-height: 830px;
    @media only screen and (max-width: 900px) {
      min-height: 100%;
      margin-bottom: 20px;
      // max-height: 430px;
    }

    .title {
      display: flex;
      gap: 9px;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 37px;
      color: #000000;
      margin-bottom: 24px;
    }
    .list_items {
      display: flex;
      justify-content: center;
      //   align-items: center;
      width: 100%;
      // flex-direction: column;
      gap: 12px;
      max-height: 800px;
      overflow: auto;
      @media only screen and (max-width: 900px) {
        max-height: 400px;
      }
      .cart_item {
        width: 100%;
      }
      &::-webkit-scrollbar {
        transform-origin: left top !important; /* align the scrollbar to the top-right corner */
        transform: rotate(360deg); /* rotate the scrollbar by 180 degrees */
      }
    }
  }

  .right {
    min-height: 830px;
    width: 100%;
    background: #f2f2f2;
    padding: 24px 60px;
    border-radius: 8px;
    @media only screen and (max-width: 1300px) {
      padding: 24px 60px;
    }
    @media only screen and (max-width: 1030px) {
      padding: 20px 30px;
    }
    @media only screen and (max-width: 900px) {
      padding: 20px 20px;
      min-height: 100%;
    }

    .title_right {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 37px;
      color: #000000;
      margin-bottom: 16px !important;
    }
    .shipping_options {
      max-height: 370px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 184px;
      // height: 500px;
      @media only screen and (max-width: 900px) {
        margin-bottom: 84px;
      }
    }
    .invoice_details {
      .title_inv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: #000000;
        margin-bottom: 16px;
      }
      .invoices {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 12px;
      }
      .taxes {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 10px;
        line-height: 19px;
        color: #868889;
        gap: 4px;
        margin-bottom: 24px;
      }
      .actions_cart {
        .send_message {
          height: 58px;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          color: #ffffff;
          border-radius: 16px;
          width: 100%;
        }
      }
    }
  }
}
