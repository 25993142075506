.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 29px;
  gap: 60px;
  justify-content: space-between;
  z-index: 10;
  overflow: scroll;
  overflow-x: hidden;
  overflow: auto;
  width: 393px;
  min-width: 393px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  background: var(--gray-6, #f2f2f2);
  @media only screen and (max-width: 1089px) {
    position: absolute;
    top: 0;
    left: -393px;
    // background: #ffffff;
  }
  &.open {
    @media only screen and (max-width: 1089px) {
      left: 0;
    }
  }
  &.open_ar {
    right: -393px;
    @media only screen and (max-width: 1089px) {
      right: 0;
    }
  }
  & > .sidebar__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    & > .logo__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      margin-bottom: 29px;
      width: 100%;
      & > .close_sidebar_trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 300ms ease;
        width: 45px;
        height: 45px;
      }
    }
    .divider {
      background: #ffd08f;
      width: 100%;
      height: 1px;
    }
    .logo {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

.sidebar_ar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 29px;
  gap: 60px;
  justify-content: space-between;
  z-index: 10;
  overflow: scroll;
  overflow-x: hidden;
  overflow: auto;
  width: 393px;
  min-width: 393px;
  background: #ffffff;
  border-radius: 8px;
  background: var(--gray-6, #f2f2f2);
  @media only screen and (max-width: 1089px) {
    position: absolute;
    top: 0;
    right: -393px;
  }
  &.open_ar {
    @media only screen and (max-width: 1089px) {
      right: 0;
    }
  }
  & > .sidebar__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    & > .logo__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      margin-bottom: 29px;
      width: 100%;
      & > .close_sidebar_trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 300ms ease;
        width: 45px;
        height: 45px;
      }
    }
    .divider {
      background: #ffd08f;
      width: 100%;
      height: 1px;
    }
    .logo {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}
