.delete_modal {
  .ant-modal {
    .ant-modal-content {
      padding: 30px;
      border-radius: 12px;
      .ant-modal-body {
        .title_delete {
          font-weight: 700;
          font-size: 24px;
          line-height: 16px;
          text-align: center;
          color: #f34b43;
          margin-bottom: 16px !important;
        }
        .subtitle_delete {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #000000;
          margin-bottom: 10px !important;
        }
        .question {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #000000;
          margin-bottom: 16px !important;
        }
        .actions {
          direction: ltr;
          .cancel_delete {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 10px;
            max-width: 80px;
            height: 32px;
            border: 1px solid #000000;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #000000;
            background-color: #ffff;
            border-radius: 12px;
            cursor: pointer;
          }
          .d_btn {
            color: #ffff;
            border-radius: 12px;
            background: #f34b43;
            border: none;
          }
        }
      }
    }
  }
}
