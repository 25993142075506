.send_message {
  width: 90%;
  outline: none;
  border: none;
  cursor: pointer;
  height: 71px;
  background: var(
    --green,
    #04c23a
  ); //   background: linear-gradient(180deg, #fd572a 0%, #f0951d 100%);
  border-radius: 11.2px;
  font-weight: 700;
  font-size: 19px;
  line-height: 37px;
  color: #ffffff;
  margin: 0;
  margin-top: 13px;
  @media only screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 26px;
    height: 58px;
    width: 100%;
  }
}

.message_wts {
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin: 3px;

  span {
    color: #085b53;
  }
}
